import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../components/circularProgress/CenteredCircularProgress';
import { useTheme, useMediaQuery, Box } from "@mui/material";
import ArtCopy from "../../components/arts/art/ArtCopy";

function Search() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

    const status = useSelector((state) => state.search.status)
    const message = useSelector((state) => state.search.message)
    const searchedWord = useSelector((state) => state.search.searchedWord)
    const searchResult = useSelector((state) => state.search.searchResult)

    const handleCardClick = () => {
        // if (type === "artist" || type === 'followingArtist') {
        //     navigate(`/artists/artistinfo/${obj._id}`, { state: { obj, type } });
        // } else if (type === "art" || type === 'profileArts') {
        //     navigate(`/arts/artinfo/${obj._id}`, { state: { obj, type } });
        // }
    };

    return (
        <div style={{ width: '100%' }}>
            {
                status === 'loading' ?
                    <div style={{ height: '80vh', width: '100%', display: 'flex', outline: "none", fontSize: isMobile ? '1.1rem' : '1.2rem', justifyContent: 'center', alignItems: "center" }}>
                        <div className="dot-container">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                    </div>
                    :
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', gap: '1rem', flexDirection: 'column' }}>
                        <div className='title2' style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                            {searchResult.length > 0 ? `Search result for '${searchedWord}'` : `${message}`}
                        </div>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: isMobile ? '2rem' : isTablet ? '2rem' : "5rem",
                                justifyContent: "center",
                                marginBottom: "2rem",
                                marginTop: "1rem",
                                outline: "none",
                                // border: 'solid'
                            }}
                        >
                            {searchResult.map((item) => (
                                <div
                                    // className="mapDiv"
                                    style={{
                                        // width: isMobile ? "10rem" : "15rem",

                                        // marginBottom: "2rem",
                                        // marginTop: "1rem",
                                        // border: 'solid red',
                                        display: 'flex',
                                        alignItems: 'end'
                                    }}
                                    key={item._id}
                                >
                                    <ArtCopy obj={item} type={item.type} />
                                </div>
                            ))}
                        </Box>
                        {/* </div> */}
                    </div>
            }
        </div >
    )
}

export default Search